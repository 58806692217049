.site-header-background {
  background: #fff !important;
  padding: 0 !important;
  // padding-left: 20px;
  padding-left: 30px !important;
  display: flex;
  justify-content: space-between;
  position: fixed;
  z-index: 999;
  // width: 100%;
  width: calc(100% - 220px);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.07);
  margin: -10px;
}

.site-header-background-branch {
  background: #fff !important;
  padding: 0 !important;
  padding-left: 20px;
  display: flex;
  justify-content: space-between;
  position: fixed;
  z-index: 999;
  width: 100%;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.07);
}

.ant-menu-horizontal {
  background: #fff !important;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.branch-name {
  margin-left: 240px;
  font-size: medium;
  font-weight: bold;
}

.breadcrumb {
  // margin-left: 240px;
  margin-top: 20px;
}

.branchSearch {
  margin-top: 20px;
}

.left-container {
  margin-left: 20px;
}

.right-container {
  display: flex;
  align-items: center;
  margin-top: -1px;
}

.ant-menu-submenu-title {
  height: 64px !important;
}

.user-name {
  color: #000000;
  margin-left: 5px;
}

.right-sub-menu-title {
  display: flex;
  margin-right: 4;
  align-items: center;
  justify-content: space-between;
}

.ant-menu-item.header {
  color: #333333;
}

.ant-menu-item.ant-menu-item-active.header {
  color: #333333;
}
